<template>
  <LayoutWithSidebar>
    <h1>プロジェクト管理 {{ projectId }}</h1>
  </LayoutWithSidebar>
</template>
<script>
import { defineComponent, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';

export default defineComponent({
  name: 'ProjectIndex',
  components: { LayoutWithSidebar },
  setup() {
    const route = useRoute();
    const projectId = ref(route.params.projectId);

    watch(
      () => route.params,
      params => {
        projectId.value = params.projectId;
      }
    );

    return { projectId };
  }
});
</script>
